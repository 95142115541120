<template>
  <component
    v-if="!hideLoginLink"
    :is="isEpiEdit ? 'span' : NuxtLink"
    v-bind="$attrs"
    :to="fixedTo"
    :target="targetOut"
    :class="{ 'external-link' : isExternalLink }"
  >
    <slot />
    {{ linkText }}
  </component>
</template>

<script setup lang="ts">
/**
 * Adds target blank to external links
 *
 * Usage.
 * Use instead of <nuxt-link>:
 * <LinkHelper to="/">
 *   or for a ISimpleLinkItem
 * <LinkHelper :link-item="something">
 *
 * Target (_blank etc) are added with this priority
 * 1. Prop target is set
 * 2. ISimpleLinkItem is set
 * 3. Fallback, external link gets auto _blank
 */
import { computed } from 'vue';
import type { ISimpleLinkItem } from '~/api-types';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
import { useGlobalContentStore } from '~/store/globalContent';
const pageStore = usePageContentStore();
const { isEpiEdit } = storeToRefs(pageStore);
const globalContentStore = useGlobalContentStore();
const NuxtLink = resolveComponent('NuxtLink');
const uiStore = useUiStore();
const { isLoggedIn } = storeToRefs(uiStore);
const host = globalContentStore.host;

const props = defineProps<{
  to?: string,
  linkItem?: ISimpleLinkItem,
  target?: string;
}>();

const fixedTo = computed(()=> {
  const output =  props.to ? props.to : props.linkItem?.linkUrl ? props.linkItem.linkUrl : '/';
  return output.replace(host, '');
});

const isExternalLink = computed(() => {
  return /^(http[s]?):/.test(fixedTo.value);
});

const hideLoginLink = computed(() => {
  return isLoggedIn.value && props.linkItem?.pageTypeName == 'LoginPage';
});

const linkText = computed(()=> {
  return props.linkItem?.linkText ? props.linkItem.linkText : null;
});

const targetOut = computed(()=> {
  if (props.target) {
    return props.target;
  }
  if (props.linkItem) {
    return props.linkItem.target ? props.linkItem.target : '';
  }
  return isExternalLink.value ? '_blank':'';
});

</script>
